
export const ANALYTICS_CONNECTIONS_PREFIX = "connection";
export const ANALYTICS_GAME_PREFIX = "game";


/**
 * Definitions of different types of events that we record.
 */
export class AnalyticsEventType {
    public static readonly SEARCH_FOR_LOBBY = new AnalyticsEventType(ANALYTICS_GAME_PREFIX, "search_for_lobby");
    public static readonly CREATE_LOBBY = new AnalyticsEventType(ANALYTICS_GAME_PREFIX, "create_lobby");
    public static readonly START_GAME = new AnalyticsEventType(ANALYTICS_GAME_PREFIX, "start");
    public static readonly ABORT_GAME = new AnalyticsEventType(ANALYTICS_GAME_PREFIX, "abort");
    public static readonly FINISH_GAME = new AnalyticsEventType(ANALYTICS_GAME_PREFIX, "finish");

    private readonly prefix: string;
    private readonly name: string;

    constructor(prefix: string, name: string) {
        this.prefix = prefix;
        this.name = name;
    }

    getPrefix(): string {
        return this.prefix;
    }

    getName(): string {
        return this.name;
    }

    getEventCategory(): string {
        return `${this.prefix}_${this.name}`;
    }
}
