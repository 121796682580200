

export function isDebugEnabled(): boolean {
    if (typeof window === "undefined")
        return false;

    const hostname = window.location.hostname;
    return hostname === "localhost" || hostname === "dev.royalur.net";
}


export function logDebug(...args: any[]) {
    if (!isDebugEnabled())
        return;

    console.log(...args);
}
