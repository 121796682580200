"use client";

import { useEffect } from "react";
import { AnalyticsEvent } from "@/ts/business/analytics/AnalyticsEvent";
import { logDebug } from "@/ts/business/debug";
import { AnalyticsEventType } from "@/ts/business/analytics/AnalyticsEventType";
import { GameAnalyticsEvent } from "@/ts/business/analytics/GameAnalyticsEvent";
import { GameMode } from "@/ts/business/game/GameMode";
import { AnalyticsProvider, LobbySettingsArg } from "@/ts/business/analytics/Analytics";
import { listenForCookieConsent } from "@/app_components/cookie/CookiePreferencesButton";
import { APIUser } from "@/ts/business/api/api_schema";
import { Milliseconds } from "@/ts/util/units";
import { getTimeMs } from "@/ts/util/utils";


type EventArray = (string | number)[];

declare global {
    interface Window {
        _paq?: EventArray[];
        clarity?: object;
    }
}


export class Matomo extends AnalyticsProvider {
    public static readonly URL: string = "https://matomo.royalur.net/";

    private initialised: boolean = false;
    private recordedUser: boolean = false;
    private lastEventTime: Milliseconds = -9999;

    isInitialised(): boolean {
        return this.initialised;
    }

    setInitialised(initialised: boolean) {
        this.initialised = initialised;
    }

    getPAQ(): EventArray[] {
        if (typeof window === "undefined")
            return [];

        if (!window._paq) {
            window._paq = [];
        }
        return window._paq;
    }

    recordUser(user: APIUser | null) {
        if (!user || this.recordedUser)
            return;

        this.recordedUser = true;
        this.getPAQ().push(["setUserId", user.publicID]);
    }

    recordEvent(event: AnalyticsEvent) {
        const time = getTimeMs();
        if (time - this.lastEventTime < 1000)
            return;

        this.lastEventTime = time;
        const eventArray: EventArray = [
            "trackEvent",
            event.getType().getEventCategory(),
            event.getAction(),
        ];

        const name = event.getName();
        if (name) {
            eventArray.push(name);
            const value = event.getValue();
            if (value) {
                eventArray.push(value);
            }
        }

        // Report the event to the Matomo analytics.
        logDebug("Analytics: Recording event [" + eventArray.join(", ") + "]");
        this.getPAQ().push(eventArray);
    }

    recordGameEvent(
        eventType: AnalyticsEventType,
        setupSettings: LobbySettingsArg,
    ) {
        const mode = setupSettings.getMode();
        const gameSettings = setupSettings.getGameType();
        const botType = setupSettings.getBotType() ?? undefined;
        if (mode === GameMode.COMPUTER && !botType)
            throw new Error("No difficulty provided with a computer game");

        this.recordEvent(new GameAnalyticsEvent(
            eventType, mode, gameSettings,
            (mode === GameMode.COMPUTER ? botType : undefined),
        ));
    }

    recordStartGame(setupSettings: LobbySettingsArg) {
        this.recordGameEvent(AnalyticsEventType.START_GAME, setupSettings);
    }

    recordAbortGame(setupSettings: LobbySettingsArg) {
        this.recordGameEvent(AnalyticsEventType.ABORT_GAME, setupSettings);
    }

    recordFinishGame(setupSettings: LobbySettingsArg) {
        this.recordGameEvent(AnalyticsEventType.FINISH_GAME, setupSettings);
    }

    recordCreateLobby(setupSettings: LobbySettingsArg) {
        this.recordGameEvent(AnalyticsEventType.CREATE_LOBBY, setupSettings);
    }

    recordSearchForLobby(setupSettings: LobbySettingsArg) {
        this.recordGameEvent(AnalyticsEventType.SEARCH_FOR_LOBBY, setupSettings);
    }
}


export const matomo: Matomo = new Matomo();


function tryInitMatomo() {
    if (matomo.isInitialised())
        return;

    matomo.setInitialised(true);
    const _paq = matomo.getPAQ();
    _paq.push(["requireCookieConsent"]);
    _paq.push(["trackPageView"]);
    _paq.push(["enableLinkTracking"]);
    _paq.push(["enableHeartBeatTimer"]);

    listenForCookieConsent((consented) => {
        if (consented) {
            _paq.push(["setCookieConsentGiven"]);
        } else {
            _paq.push(["forgetCookieConsentGiven"]);
        }
    });

    (function () {
        _paq.push(["setTrackerUrl", Matomo.URL + "matomo.php"]);
        _paq.push(["setSiteId", "1"]);
        const matomoScript = document.createElement("script");
        matomoScript.type = "text/javascript";
        matomoScript.async = true;
        matomoScript.src = Matomo.URL + "matomo.js";

        const scriptElems = document.getElementsByTagName("script");
        if (scriptElems.length > 0 && scriptElems[0].parentNode) {
            scriptElems[0].parentNode.insertBefore(matomoScript, scriptElems[0]);
        } else {
            document.body.appendChild(matomoScript);
        }
    })();

    // setupClarity(window, document, "clarity", "script", "ngj5n96ty0");
}


export function MatomoProvider() {
    useEffect(() => {
        tryInitMatomo();
    }, []);
    return <></>;
}
