import { AnalyticsEvent } from "@/ts/business/analytics/AnalyticsEvent";
import { AnalyticsEventType } from "@/ts/business/analytics/AnalyticsEventType";
import { LobbySettings } from "@/ts/business/game/LobbySettings";
import { ApproximateLobbySettings } from "@/ts/business/game/ApproximateLobbySettings";


export type LobbySettingsArg = LobbySettings | ApproximateLobbySettings;


export abstract class AnalyticsProvider {
    abstract recordEvent(event: AnalyticsEvent): void;

    abstract recordGameEvent(
        eventType: AnalyticsEventType,
        setupSettings: LobbySettingsArg,
    ): void;

    abstract recordStartGame(setupSettings: LobbySettingsArg): void;

    abstract recordAbortGame(setupSettings: LobbySettingsArg): void;

    abstract recordFinishGame(setupSettings: LobbySettingsArg): void;

    abstract recordCreateLobby(setupSettings: LobbySettingsArg): void;

    abstract recordSearchForLobby(setupSettings: LobbySettingsArg): void;
}
