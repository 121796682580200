import { useEffect, useState } from "react";


export function useDocument(): Document | null {
    const [theDocument, setTheDocument] = useState<Document | null>(null);

    useEffect(() => {
        setTheDocument(document);
    }, []);

    return theDocument;
}
